<template>
  <div class="select-renderer" v-if="this.params.node.group === false">
    <v-select
      v-model="selectedOption"
      :options="options"
      :clearable="true"
      :no-async="true"
      appendToBody
      v-on:input="onSelectChange"
      :getOptionLabel="(option) => option[field]"
      :selectable="(option) => !option.disabled"
    >
      <template #selected-option="option">
        <span style="text-transform: uppercase;">{{ option[field] }}</span>
      </template>
      <template #option="option">
        <span style="text-transform: uppercase">{{ option[field] }}</span>
      </template>
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  data() {
    return {
      selectedOption: null,
      options: [],
      field: null
    }
  },
  mounted() {
    this.field = this.params.field
    this.options = this.params.options
    this.selectedOption = this.options.find(
      (o) => o[this.field].includes(this.params?.value?.[this.field])
    )
  },
  components: {
    vSelect
  },
  methods: {
    onSelectChange(selectedValue) {
      this.params.node.setDataValue(
        this.params.colDef.field,
        selectedValue
      )
    }
  }
}
</script>
